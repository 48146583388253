import type { ModuleNamespace } from 'vite/types/hot';
import type { App } from 'vue';

export function registerLayouts(app: App<Element>): void {
  const layouts = import.meta.globEager<string, ModuleNamespace>('./*.vue');

  Object.entries(layouts).forEach(([, layout]: [string, ModuleNamespace]) => {
    app.component(layout.default.__name, layout.default);
  });
}
